import React, { PureComponent } from 'react';
import SpaceSelector from './SpaceSelector';
import moment from 'moment-timezone';
import Select from 'react-select';
import styled from 'styled-components';
import { findBookingAvailabilitiesEndpoint } from './Api';
import Calendar, { formatTimeSlotTokey } from './Calendar';
import Sidebar from 'react-sidebar';
import SidebarContent from './SidebarContent';

/**
 *
 * TODO: <Login> Block if use is authenticated with
 *     : <OnClick> Calendar event modal send email
 *        ---> Show next availabilities Store them in the event after second pass <--- [a,b]
 *        ---> Confirm?
 *        ---> Cloud function send email to sales with selected futures dates in the object?
 *
 *
 */
export const BookingContext = React.createContext({
  space: null,
  weeks: null,
  days: null,
  times: null,
  user: null,
  events: [],
  selectedEvents: {},
  setSelectedEvent: () => {},
  panelOpen: null,
  setPanelOpen: () => {},
  setEvents: () => {},
  setSpace: () => {},
  setWeeks: () => {},
  setDays: () => {},
  setTime: () => {},
  setUser: () => {},
});

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const daysOptions = days.map((x, i) => ({
  value: i,
  label: x,
}));

const weeksOptions = Array.from(Array(15), (x, i) => ({
  value: i + 1,
  label: i + 1,
}));

const increments = 30;
const start = moment()
  .tz('America/Montreal')
  .startOf('day');

const timeOptions = Array.from(Array(24), (x, i) => {
  if (i) start.add(increments, 'minutes');
  return {
    value: start.format('hh:mm a'),
    label: start.format('hh:mm a'),
  };
});

const SelectWrapper = styled.div`
  margin-top: 10px;
  padding: 15px;
`;

const SilofitButton = styled.button`
  border-radius: 15px;
  width: 100%;
  padding: 10px;
  font-family: Roboto;
  font-size: 14px;
  text-align: center;
  background-color: #ff9322;
  color: white;
  text-transform: uppercase;
  margin: 0px;
  margin-top: 20px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  &:disabled {
    background: #fafafa;
    color: #dedede;
  }
`;

class MainWrapper extends PureComponent {
  setSpace = space => {
    console.log('Setspace called');
    this.setState({ space });
  };
  setEvents = events => this.setState({ events });
  setWeeks = weeks => this.setState({ weeks });
  setDays = days => this.setState({ days });
  setTimes = times => this.setState({ times });
  setPanelOpen = bool => this.setState({ panelOpen: bool });
  removeEventsBlock = (selectedEventKey, preventUpdateState = false) => {
    console.log('Removing Event at key', selectedEventKey);
    const { selectedEvents, events } = this.state;
    const selectedEvent = selectedEvents[selectedEventKey];
    // Do a css cleanup of this block + future
    const currentEventFromCalendarEvents = this.findSlotFromEvents(
      selectedEvent,
      events
    );

    currentEventFromCalendarEvents.classNames = [];
    currentEventFromCalendarEvents.futureSlots.forEach(slot => {
      const event = events.find(({ start }) => start === slot.start);
      if (event) event.classNames = [];
    });

    // Remove the key and update state
    delete selectedEvents[selectedEventKey];
    // Todo: state.events should come from the function calling removeEventsBlock to keep it pure.
    if (!preventUpdateState)
      this.setState({ selectedEvents, events: [...this.state.events, {}] });
  };
  findSlotFromEvents = (slot, events) => {
    return events.find(
      ({ start }) => slot.start.toString() === new Date(start).toString()
    );
  };

  findFutureSlotFromEventsAndCleanClasses = (slot, events) => {
    const calendarStateEvent = this.findSlotFromEvents(slot, events);
    if (calendarStateEvent) {
      calendarStateEvent.classNames = calendarStateEvent.classNames.filter(x =>
        x.match(/availability/)
      );
    }
  };

  setSelectedEvent = fullcalendarClickEvent => {
    /**
     * This function juggles between FullCalendar Events
     * and its corresponding mapping as an event object:
     *  {
     *    start,
     *    end,
     *    futureSlots, -> is an [timeStamps(:start)] that maps to the FullCalendarEvents
     *    classNames
     *  }
     */
    const { events } = this.state;
    const { event } = fullcalendarClickEvent;
    const { start, end, classNames } = event;
    // If this event is part of a previously selected
    // recurring, remove the recurring one and set it to this one
    const { selectedEvents } = this.state;
    const selectedEventKey = formatTimeSlotTokey(start, end);

    // existingEventForKey can be any Monday at this timeslot for example
    const existingEventForKey = selectedEvents[selectedEventKey];

    const updateSelectedEventFutureSlotsClasses = calendarSelectedStateEvent => {
      calendarSelectedStateEvent.futureSlots.forEach(slot => {
        // Map to the real state event array item passed to <FullCalendar> to edit it
        const calendarStateEvent = events.find(
          ({ start }) => start === slot.start
        );
        calendarStateEvent &&
          calendarStateEvent.classNames &&
          calendarStateEvent.classNames.push('selection-next');
      });
    };

    if (existingEventForKey) {
      if (classNames.includes('selection-start')) {
        // The clicked element for this day was a selection start,
        // remove it and its following blocks
        return this.removeEventsBlock(selectedEventKey);
      }
      this.removeEventsBlock(selectedEventKey, true); //
    }
    selectedEvents[selectedEventKey] = event;
    const selectedDayEventFromCalendarState = this.findSlotFromEvents(
      event,
      events
    );
    updateSelectedEventFutureSlotsClasses(selectedDayEventFromCalendarState);
    selectedDayEventFromCalendarState.classNames = ['selection-start'];
    // Add the class to the currently selected
    //events.push({});
    // Stupid Calendar won't reload if same items length !?
    console.log('SelectedEvents', selectedEvents);
    this.setState({
      events: [...events, {}],
      selectedEvent: event,
      selectedEvents,
      panelOpen: true,
    });
  };

  findTimeSlots = async () => {
    // Unused for now
    this.setState({ loading: true });
    const { space, weeks, days, times } = this.state;
    const params = {
      space: space.value,
      weeks: weeks.value,
      days: days.value,
      times: times.value,
    };
    const potentialBookings = await fetch(findBookingAvailabilitiesEndpoint, {
      method: 'post',
      body: JSON.stringify(params),
    });
    console.log(potentialBookings);
    await this.setState({ loading: false });
  };

  state = {
    space: null,
    weeks: null,
    days: null,
    slots: [],
    events: [],
    selectedEvents: {},
    panelOpen: false,
    setEvents: this.setEvents,
    setSelectedEvent: this.setSelectedEvent,
    setSpace: this.setSpace,
    setWeeks: this.setWeeks,
    setDays: this.setDays,
    setTimes: this.setTimes,
  };

  selectors() {
    return (
      <div>
        <SelectWrapper>
          <Select
            placeholder="Select the day"
            isDisabled={!this.state.space || this.state.loading}
            options={daysOptions}
            onChange={this.setDays}
          />
        </SelectWrapper>

        <SelectWrapper>
          <Select
            placeholder="Select the time"
            isDisabled={!this.state.space || this.state.loading}
            options={timeOptions}
            onChange={this.setTimes}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            placeholder="Select how many weeks"
            isDisabled={!this.state.space || this.state.loading}
            options={weeksOptions}
            onChange={this.setWeeks}
          />
        </SelectWrapper>
        <SilofitButton
          disabled={
            this.state.loading ||
            !this.state.space ||
            !this.state.days ||
            !this.state.weeks ||
            !this.state.times
          }
          onClick={this.findTimeSlots}
        >
          Find time slots
        </SilofitButton>
      </div>
    );
  }
  render() {
    console.log('Rendering!');
    const logout = (
      <button
        style={{
          marginLeft: '5px',
        }}
        className="primarySiloButton"
        onClick={() => {
          //debugger;
          this.props.firebaseApp.auth().signOut();
        }}
      >
        Sign out
      </button>
    );
    return (
      <Sidebar
        sidebar={
          <SidebarContent
            token={this.props.token}
            user={this.props.user}
            space={this.state.space && this.state.space.value}
            events={this.state.selectedEvents || {}}
            removeEvents={this.removeEventsBlock}
            close={() => this.setPanelOpen(false)}
          />
        }
        open={false}
        docked={this.state.panelOpen}
        onSetOpen={() => {}}
        shadow={false}
        pullRight={true}
        touch={true}
        overlayId="sidebar-overlay"
        contentId="sidebar-content"
        transitions={true}
        styles={{
          sidebar: {
            background: 'white',
          },
        }}
      >
        <div className="contentBlock">
          <div className="headerBlock">
            <div className="waitlistBlock">
              <a
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeuh-ODvAri_Y2Zcq6Ll4Nf64oLrB7l0oGJ8q_qhnkVW2Z03g/viewform?usp=sf_link"
              >
                Can’t find the spot you’re looking for? <b>Join the waitlist</b>
              </a>
            </div>
            <div className="loginBlock">
              {this.props.user.isAnonymous && 'Session expired please:'}
              {this.props.user.email && (
                <span>Welcome, {this.props.user.email}</span>
              )}{' '}
              {logout}
            </div>
          </div>
          <BookingContext.Provider value={this.state}>
            <SelectWrapper className="bodyWrapper">
              <React.Fragment>
                <BookingContext.Consumer>
                  {({ space, setSpace }) => {
                    return <SpaceSelector space={space} setSpace={setSpace} />;
                  }}
                </BookingContext.Consumer>
              </React.Fragment>
              <React.Fragment>
                <BookingContext.Consumer>
                  {({ setSelectedEvent, space, setEvents, events }) => {
                    return (
                      space && (
                        <Calendar
                          events={events}
                          setEvents={setEvents}
                          space={space}
                          setSelectedEvent={setSelectedEvent}
                        />
                      )
                    );
                  }}
                </BookingContext.Consumer>
              </React.Fragment>
            </SelectWrapper>
          </BookingContext.Provider>
        </div>
      </Sidebar>
    );
  }
}

export default MainWrapper;
