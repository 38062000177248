// Todo: Create APP in Project Settings + App Web
export default {
  apiKey: 'AIzaSyDveZHsD6S76Wn1O8nW6NcPZLtn9mVAsEY',
  authDomain: 'silofit-prod.firebaseapp.com',
  databaseURL: 'https://silofit-prod.firebaseio.com',
  projectId: 'silofit-prod',
  storageBucket: 'silofit-prod.appspot.com',
  messagingSenderId: '485996734465',
  appId: '1:485996734465:web:9b33522b1d9593b1',
};
