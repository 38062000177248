import React, { useState, useRef } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import { ReactComponent as LoopIcon } from '../assets/baseline-refresh-24px.svg';
import { ReactComponent as AvailableIcon } from '../assets/baseline-event_available-24px.svg';
import { ReactComponent as BusyIcon } from '../assets/baseline-event_busy-24px.svg';
import { ReactComponent as CloseIcon } from '../assets/baseline-close-24px.svg';
import { ReactComponent as Icon } from '../icon.svg';
import { confirmBookingAvailabilitiesEndpoint } from './Api';

const serializeEvents = events => {
  const serialized = {};
  Object.keys(events).forEach(eventKey => {
    const oldEventApi = events[eventKey];
    const {
      start,
      extendedProps: { futureSlots },
    } = oldEventApi;
    const datesArray = [...futureSlots.map(({ start }) => moment(start))];
    datesArray.unshift(moment(start));
    serialized[eventKey] = datesArray;
  });
  return serialized;
};
const mapEventsBlocks = (eventBlockKey, events, removeEvents, index) => {
  const eventBlock = events[eventBlockKey];
  const {
    start,
    end,
    extendedProps: { futureSlots, resources },
  } = eventBlock;
  // Contains an EventApi from FullCalendar
  const momentStart = moment(start).tz('America/Montreal');
  const day = momentStart.format('dddd');

  const lastDay =
    (futureSlots &&
      futureSlots.length &&
      futureSlots[futureSlots.length - 1]) ||
    day;
  const momentEnd = lastDay && moment(lastDay.end).tz('America/Montreal'); // Todo: Silo Timezone

  const hoursStart = momentStart.format('hh:mm a');
  const hoursEnd = momentEnd.format('hh:mm a');

  let startEndString;
  let occurencesArray = [];
  occurencesArray.push({
    date: momentStart.format('YYYY-MM-DD'),
    present: true,
  });
  if (lastDay) {
    startEndString =
      momentStart.format('MMM DD') + ' to ' + momentEnd.format('MMM DD');

    let startDate = momentStart.clone();
    const futureSlotsStartArray = eventBlock.extendedProps.futureSlots.map(x =>
      moment(x.start).toString()
    );
    for (var i = 0; i < 4; i++) {
      const newTime = startDate.add(1, 'week');
      const newTimeStarttoString = newTime.toString();
      const hasDate = futureSlotsStartArray.includes(newTimeStarttoString);
      occurencesArray.push({
        date: newTime.format('YYYY-MM-DD'),
        present: hasDate,
      });
    }
  }

  return (
    <div className="eventBlock" key={index}>
      <div className="eventBlockHeader">
        <div className="eventBlockDelete">
          <CloseIcon onClick={() => removeEvents(eventBlockKey)} />
        </div>
        <LoopIcon className="loopIcon" />
        <div className="eventDay">
          {futureSlots.length ? 'Weekly' : 'Weekly'} on {day}
        </div>
        <div className="eventTime">
          {hoursStart} - {hoursEnd}
        </div>
      </div>
      {/*<div className="eventBlockDateRange">
        {lastDay ? startEndString : momentStart.format('MMM ddd hh:mm a')}
  </div>*/}
      <div className="eventBlockSpecificDates">
        {occurencesArray.map(({ date, present }) => (
          <div
            key={date}
            className={
              present === true ? 'eventBlockOneDate' : 'eventBlockOneDate busy'
            }
          >
            {present ? <AvailableIcon /> : <BusyIcon />}{' '}
            <div className="eventBlockDate">{date}</div>
          </div>
        ))}
      </div>
    </div>
  );
  // start, end, extendedProps -> { futureSlots, resources }
};

const SidebarContent = ({
  token,
  user,
  events,
  space,
  removeEvents,
  close,
}) => {
  const [isFocused, setIsFocused] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const [isDone, setDone] = useState(0);
  const [isError, setError] = useState(0);
  const inputEl = useRef(null);
  if (!events || !Object.keys(events).length) return null;

  const eventsBlocks = Object.keys(events);
  const sideBarClassName =
    'SidebarContentWrapper ' +
    (isLoading ? 'isLoading ' : '') +
    (isFocused ? 'focusTextarea' : '');

  const onConfirmClick = async () => {
    setIsLoading(true);
    const newToken = await user.getIdToken(true);
    const additional = inputEl.current.value;
    const params = {
      additional,
      events: serializeEvents(events),
      space,
      token: newToken,
    };
    let response;
    try {
      response = await axios.post(confirmBookingAvailabilitiesEndpoint, params);
      // If response.done -> isDone true -> setTimeout(revertAll)l
      // Skin html in the cloud function
    } catch (e) {
      console.error(e);
      setError(e);
    }
    if (response.data && response.data.error)
      setError(response.data.error.message);
    setDone(true);
    setTimeout(() => {
      // Clear selection
      Object.keys(events).forEach(eventKey => {
        removeEvents(eventKey);
      });
      // Remove loading & close panel
      setError(0);
      setIsLoading(false);
      close();
    }, 2800);
  };
  return (
    <div className={sideBarClassName}>
      {!!isLoading && (
        <div className="SideBarLoadingBlock">
          {!isDone && (
            <div className="rotatesvg loading">
              <div className="iconWrapper">
                <Icon />
              </div>
            </div>
          )}
          {!!isDone && !isError && (
            <div className="SideBarLoadingDone">Request Sent! </div>
          )}
          {!!isDone && !!isError && (
            <div className="SideBarLoadingDone">
              Error: {isError} :( ! Please retry or contact sales@silofit.com{' '}
            </div>
          )}
        </div>
      )}
      {/*<button onClick={close}>X</button>*/}
      <div className="SidebarBlocks">
        {eventsBlocks.map((eventBlockKey, index) =>
          mapEventsBlocks(eventBlockKey, events, removeEvents, index)
        )}
      </div>
      <div className="SideBarConfirm">
        <div className="SideBarTextAreaWrapper">
          <textarea
            ref={inputEl}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder="Leave a note (optional)"
          />
        </div>
        <div className="SideBarButtonWrapper">
          <button
            className="primarySiloButton primarySiloButton--full"
            onClick={onConfirmClick}
          >
            {isLoading ? 'Sending...' : 'Send request'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SidebarContent;
