import React, { useEffect } from 'react';
import Select from 'react-select';
import { BookingContext } from './MainWrapper';
import firebase from 'firebase';
import { useList } from 'react-firebase-hooks/database';

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const makeOptions = snapshots => {
  return snapshots.map(snapshot => {
    const space = snapshot.val();
    return {
      value: space.space_id,
      label: space.name,
      spaceObject: space,
    };
  });
};
const SpaceSelector = ({ space, setSpace }) => {
  const [snapshots, loading, error] = useList(
    firebase.database().ref('prebook_spaces')
  );
  const options = makeOptions(snapshots);
  console.log(`Listing again Space! Loading: ${loading}`);

  useEffect(() => {
    if (!loading && snapshots && !space) {
      setSpace(options[0]);
    }
  });
  return (
    <div>
      {error && <ErrorBoundary>Error: {error.toString()}</ErrorBoundary>}
      {!error && (
        <Select
          styles={{
            control: (base, state) => ({
              ...base,
              ':active': {
                borderColor: state.isSelected ? '#CE9B6C' : 'white',
              },
              ':focus': {
                borderColor: state.isSelected ? '#CE9B6C' : 'white',
              },
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isSelected ? '#CE9B6C' : 'white',
              ':active': {
                backgroundColor: state.isSelected ? '#CE9B6C' : 'white',
              },
            }),
          }}
          className={space ? '' : 'rotatesvg'}
          loading={loading}
          isDisabled={loading}
          placeholder="Select a Silo"
          value={space}
          loadingMessage="Loading Spaces..."
          onChange={setSpace}
          options={options}
        />
      )}
    </div>
  );
};
//SpaceSelector.whyDidYouRender = true;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default SpaceSelector;
