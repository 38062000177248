import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timekit from 'timekit-sdk';
import moment from 'moment-timezone';
import { ReactComponent as Icon } from '../icon.svg';
import './Calendar.scss';

export const formatTimeSlotTokey = (start, end) =>
  moment(start).format('ddd hh:mm a') + '-' + moment(end).format('ddd hh:mm a');
export default class Calendar extends React.Component {
  state = {
    loading: true,
  };
  eventClick = info => {
    // This will need to set the context of seleted event to the current event + extendedProps
    console.log(info);
    // Maybe slice the events?
    this.props.setSelectedEvent(info);
  };
  updateEvents = events => {
    this.props.setEvents(events);
  };
  loadEvents = async props => {
    console.log('Loading Events!!!');
    let availabilitiesResponse = {};
    try {
      availabilitiesResponse = await timekit.fetchAvailability({
        output_timezone: this.props.space.spaceObject.timezone,
        project_id: this.props.space.spaceObject.timekit_project_id,
        mode: 'roundrobin_random',
        length: '1 hour',
        from: 'now',
        to: '6 weeks',
        buffer: `${this.props.space.spaceObject.timekit_buffer || 0} minutes`,
        timeslot_increments: '30 minutes',
        ignore_all_day_events: false,
      });
      if (!availabilitiesResponse.status === 200) throw new Error('Error');
    } catch (err) {
      console.error(err);
      alert(err);
    }
    let availabilities = availabilitiesResponse.data
      ? availabilitiesResponse.data
      : [];
    // console.log(availabilities);
    const availabilityMap = {};
    const eventsNumber = availabilities.length;
    let loops = 0;
    //console.log(`Entities: ${eventsNumber} - ${eventsNumber ** 2}`);
    availabilities.forEach(availability => {
      const { start, end } = availability;
      const timeSlot = formatTimeSlotTokey(start, end);

      if (!(timeSlot in availabilityMap)) availabilityMap[timeSlot] = [];
      availabilityMap[timeSlot].push({ start, end }); // Will be checked against that for the 2nd pass
    });
    availabilities.forEach(availability => {
      const { start, end } = availability;
      const timeSlot = formatTimeSlotTokey(start, end);
      const allSlotsForTimeSlot = availabilityMap[timeSlot];
      const availabilityMomentStart = moment(start);
      const availabilityMomentEnd = moment(end);
      const availability5WeeksEnd = availabilityMomentEnd
        .clone()
        .add(5, 'weeks');
      const futureSlots = allSlotsForTimeSlot.filter(({ end }) => {
        loops++;
        const momentEndSlot = moment(end);
        return (
          availabilityMomentStart.isBefore(momentEndSlot) &&
          availabilityMomentEnd.isBefore(momentEndSlot) &&
          momentEndSlot.isBefore(availability5WeeksEnd)
        );
      });
      availability.futureSlots = futureSlots; // Save them so they show in the click event of event.extendedProps
      const totalFutureSlotsForTimeSlot = 1 + futureSlots.length;
      //   console.log(
      //     `${timeSlot} has { ${totalFutureSlotsForTimeSlot} } future availabilities`
      //   );
      availability.title = `${totalFutureSlotsForTimeSlot} week${
        totalFutureSlotsForTimeSlot > 1 ? 's' : ''
      } available`;
      availability.classNames = [`availability-${totalFutureSlotsForTimeSlot}`];
    });
    // console.log(`Loops: ${loops}`);
    // https://fullcalendar.io/docs/event-object
    this.setState({ loading: false });
    this.props.setEvents(availabilities || []);

    // const availabilities = await fetch(
    //   'https://api.timekit.io/v2/availability',
    //   {
    //     method: 'POST',
    //     body: {
    //       output_timezone: 'America/Montreal',
    //       project_id: '5cd55254-4762-4b45-b58b-aa6f69e3750a',
    //       mode: 'roundrobin_random',
    //       length: '1 hour',
    //       from: 'now',
    //       to: '10 weeks',
    //       buffer: '1 hour',
    //       timeslot_increments: '30 minutes',
    //       ignore_all_day_events: false,
    //     },
    //   }
    // );
  };

  componentDidUpdate(prevProps) {
    if (this.props.space.value !== prevProps.space.value) {
      console.log('New props for calendar, loading events');
      this.setState({ loading: true });
      this.loadEvents();
    }
  }
  componentDidMount() {
    timekit.configure({
      appKey: 'live_widget_key_BiGBkuw0A4FKNCEf4xIVqFWvGyTNe58R', // Todo: make modular
    });
    this.loadEvents();
  }
  render() {
    // https://fullcalendar.io/docs#toc
    const { loading } = this.state;
    const { events, space } = this.props;
    return (
      <div>
        {loading && (
          <div className="rotatesvg loading">
            Loading...
            <div className="iconWrapper">
              <Icon />
            </div>
            {/*<div className="details">{space.spaceObject.description}</div>*/}
          </div>
        )}
        {!loading && (
          <FullCalendar
            space={this.props.space}
            defaultView="dayGridWeek"
            plugins={[dayGridPlugin]}
            eventClick={this.eventClick}
            events={events}
            firstDay={0}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: true,
              meridiem: 'short',
            }}
            // slotLabelFormat={{
            //   hour: 'numeric',
            //   minute: '2-digit',
            //   meridiem: 'short',
            // }}
            // timeFormat={{
            //   hour: 'numeric',
            //   minute: '2-digit',
            //   meridiem: 'short',
            // }}
            nowIndicator={true}
            views={{
              week: {
                displayEventEnd: true,
              },
            }}
          />
        )}
      </div>
    );
  }
}
