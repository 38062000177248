import React, { Component } from "react";
import logo from "./logo.png";
import "./App.scss";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebaseConfig from "./config";
import * as Sentry from "@sentry/browser";
import firebase from "firebase/app";
// Todo: Detect LOCAL / DEV / PROD

import MainWrapper from "./component/MainWrapper";
Sentry.init({
  dsn: "https://8de61ced89914129a633344600da5001@sentry.io/1532826",
});
require("firebase/auth");
let firebaseui = require("firebaseui");

let firebaseApp = null;
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} else {
  firebaseApp = firebase.app();
}

class App extends Component {
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };
  state = {
    isSignedIn: null,
    user: null,
  };
  componentDidMount() {
    require("firebaseui/dist/firebaseui.css");
    const that = this;
    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(async (user) => {
        let state = {
          isSignedIn: !!user,
          user: null,
          token: null,
          firebaseApp,
        };
        if (user) {
          state.user = user;
          const token = await user.getIdToken(true);
          state.token = token;
          Sentry.configureScope((scope) => {
            scope.setUser({ email: user.email });
          });
        }
        //debugger;
        that.setState(state);
      });
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  loggedInView() {
    return <MainWrapper {...this.state} />;
  }
  loggedOutView() {
    return (
      <div className="unlogged">
        <div className="logoWrapper">
          <img src={logo} alt="Silofit" />
        </div>
        <p className="loginDetails">
          Use the same login method than the Silofit app
        </p>
        <StyledFirebaseAuth
          uiConfig={this.uiConfig}
          firebaseAuth={firebaseApp.auth()}
        />
        {/*
        <button
          onClick={() => {
            firebaseApp.auth().signInAnonymously();
          }}
        >
          Sign in anonymously
        </button>
        */}
      </div>
    );
  }

  render() {
    return this.state.isSignedIn ? this.loggedInView() : this.loggedOutView();
  }
}

export default App;
